<template>

  <div class="container">
    <div class="row sm-full">
    </div>
  </div>
  
</template>

<script>
import { mapState, mapActions } from "vuex";
import { directive as onClickaway } from 'vue-clickaway';
export default {
  directives: {
    onClickaway: onClickaway,
  },
  data: () => ({
    
  }),
  
  async created() {},
  mounted() {
  
  },
  computed: {

  },
  methods: {
  
  },
};
</script>

<style>

</style>
