// import { axios } from "vue/types/umd";

export default {
  namespaced: true,

  state: () => ({
    profile: {},
    loader:false,
  }),

  getters: {
    profile(state) {
      return state.profile;
    },
  },

  mutations: {
    populateProfile(state,newProfile){
      state.profile = newProfile;
    },
    LOADER(state,payload){
      state.loader = payload
    },
  },

  actions: {
    getProfile({commit},apiarg) {
      return new Promise((resolve, reject) => {
        axios.get('profile/'+apiarg)
    .then(res => {
        resolve(res);
        console.log("data",res.data)
          commit("populateProfile",res.data.profile);
    })
        .catch((error) => {
          reject(error);
        });
    });
    },
  }
};
