<template>
  <ProfilePage />
</template>

<script>
import ProfilePage from '../components/ProfilePage'
import {mapActions,mapState} from "vuex"
export default {
  name: 'Profile',
  components: {
    ProfilePage,
  },
  async created() {

  },
  computed: {
  },
  methods: {

  }
}
</script>
