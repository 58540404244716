<template>
  <main class="team-page">

        <!-- Review start -->
        <section class="team" v-if="profile">
            <div class="container">
                <div class="page-heading">
                    <div class="heading">
                        <h2 class="s-heading">
                            Details of {{profile.name}} 
                        </h2>
                    </div>
                </div>
                <div class="row mt-20">
                    <div class="col-md-6 col-lg-5 col-xl-3 mb-25">
                        <div class="t-member-profile position-relative overflow-hidden pb-20">
                            <div class="t-member-profile-img position-relative">
                                <img :src="`${baseEnvLocal}${profile.image}`" alt="team">
                            </div>

                        </div>
                        <div class="t-member-des">
                            <img :src="require('@/assets/img/team_name.png')" alt="">
                            <div class="team-deg">
                                <h5>{{profile.name}}</h5>
                                <p>{{profile.designation}}</p>
                            </div>

                        </div>
                        <div class="social-info mt-30">
                            <div class="d-flex align-items-center gap-3 mb-5">
                                <div class="f-s-icon ">
                                    <a href="# " class="so-f ">
                                        <img :src="require('@/assets/img/call.png')" alt="facebook ">
                                    </a>
                                </div>
                                <a href="tel:+88 (0) 174 396 6200" class="contct-c">{{profile.phone}}</a>
                            </div>
                            <div class="d-flex align-items-center gap-3 mb-5">
                                <div class="f-s-icon">
                                    <a href="# " class="so-f ">
                                        <img :src="require('@/assets/img/message.png')" alt="facebook">
                                    </a>
                                </div>
                                <a :href="'mailto:'+profile.email" class="contct-c">{{profile.email}}</a>
                            </div>


                            <div class="f-s-icon mt-25">
                                <a :href="profile.facebook" v-if="profile.facebook" target="_blank" class="so-f ">
                                    <img :src="require('@/assets/img/facebook.png')" alt="facebook ">
                                </a>
                                <a :href="profile.twitter" v-if="profile.twitter" target="_blank" class="so-t ">
                                    <img :src="require('@/assets/img/twitter.png')" alt="twitter ">
                                </a>
                                <a :href="profile.linkedin" v-if="profile.linkedin" target="_blank" class="so-i ">
                                    <img :src="require('@/assets/img/linkedin.png')" alt="linkedin ">
                                </a>
                                <a :href="profile.google" v-if="profile.google" target="_blank" class="so-l ">
                                    <img :src="require('@/assets/img/instragram.png')" alt="instragram ">
                                </a>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6 col-lg-7 col-xl-9 mb-25 pl-2">
                        <div class="motivation">
                            <h5 class="t-title">
                                Motivation:
                            </h5>
                            <p class="m-con" :style="{fontSize:'15px'}" v-html="profile.des">
                            </p>
                        </div>
                        <div class="skill mt-30">
                            <h5 class="t-title">
                                Skill:
                            </h5>
                            <div class="skills" v-if="profile.skill && profile.skill.length">
                                <div class="skills-bar">
                                    <div class="bar-s">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-4 bar" v-for="(skill,index) in profile.skill" :key="index">
                                                <div class="info">
                                                    <span>{{skill.name}}</span>
                                                </div>
                                                <!-- <div class="progress-line"><span class="html"></span></div> -->
                                                <v-progress-linear :value="skill.percentage"  height="20" background-color="grey lighten-2" color="blue lighten-1" class="progress-line" ><div class="text-center text-white">{{ skill.percentage}}%</div></v-progress-linear>                             
                                            </div>
                                            
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="education mt-25">
                    <div class="row">
                        <div class="col-md-6 mb-25" v-if="profile.background && profile.background.length">
                            <div class="edu-title">
                                <h5 class="t-title">
                                    Background :
                                </h5>
                            </div>
                            <div class="edu-background mt-30" v-for="(background, sl) in profile.background" :key="sl">
                                <div class="edu-his mb-25">
                                    <div class="edu-img mb-15">
                                        <img :src="require('@/assets/img/carbon_education.png')" alt="">
                                    </div>
                                    <div class="edu-qua">
                                        <h5 class="uni-name">{{background.institution_name}}</h5>
                                        <h6 class="course-n">{{background.degree}} in {{background.department}}</h6>
                                        <p class="duration">{{background.duration}}</p>
                                        <p class="grade" v-if="background.grade">Grade : {{background.grade}} out of {{background.out_of}}</p>
                                        <p class="uni-con">{{background.description}} </p>
                                    </div>

                                </div>
                                
                            </div>


                        </div>
                        <div class="col-md-6 mb-25" v-if="profile.experience && profile.experience.length">
                            <div class="edu-title">
                                <h5 class="t-title">
                                    Experience :
                                </h5>
                            </div>
                            <div class="exp-background  mt-30" v-for="(exprience,ex) in profile.experience" :key="ex">
                                <div class="edu-his mb-25">
                                    <div class="edu-img mb-15">
                                        <img :src="require('@/assets/img/Vector.png')" alt="">
                                    </div>
                                    <div class="edu-qua">
                                        <h5 class="uni-name">{{exprience.company_name}}</h5>

                                        <p class="duration"> {{exprience.duration}} </p>

                                        <p class="uni-con">{{exprience.description}} </p>
                                    </div>

                                </div>
                                <!-- <div class="edu-his mb-25">
                                    <div class="edu-img mb-15">
                                        <img src="assets/img/team/Vector (2).png" alt="">
                                    </div>
                                    <div class="edu-qua">
                                        <h5 class="uni-name">Bangladesh University of Engineering & Technology</h5>

                                        <p class="duration"> 19Sep, 2020 - Present </p>

                                        <p class="uni-con">Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. </p>
                                    </div>

                                </div>
                                <div class="edu-his mb-25">
                                    <div class="edu-img mb-15">
                                        <img src="assets/img/team/Vector (2).png" alt="">
                                    </div>
                                    <div class="edu-qua">
                                        <h5 class="uni-name">Bangladesh University of Engineering & Technology</h5>

                                        <p class="duration"> 19Sep, 2020 - Present </p>

                                        <p class="uni-con">Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. </p>
                                    </div>

                                </div>
                                <div class="edu-his mb-25">
                                    <div class="edu-img mb-15">
                                        <img src="assets/img/team/Vector (2).png" alt="">
                                    </div>
                                    <div class="edu-qua">
                                        <h5 class="uni-name">Bangladesh University of Engineering & Technology</h5>

                                        <p class="duration"> 19Sep, 2020 - Present </p>

                                        <p class="uni-con">Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. </p>
                                    </div>

                                </div> -->

                            </div>


                        </div>
                    
                        <div class="col-md-7 mb-25" v-if="profile.certificate && profile.certificate.length">
                            <div class="edu-title">
                                <h5 class="t-title">
                                    Certificate :
                                </h5>
                            </div>
                            <div class="cir-background mt-30" v-for="(certificate, cer) in profile.certificate" :key="cer">
                                <div class="edu-his mb-25">
                                    <div class="cir-img mb-15">
                                        <img :src="certificate.image ? `${baseEnvLocal}${certificate.image}` : require('@/assets/img/Vector.png')" alt="">
                                    </div>
                                    <div class="edu-qua">
                                        <h5 class="uni-name">{{certificate.institution_name}}</h5>
                                        <h6 class="course-n">{{certificate.degree}} in {{certificate.department}}</h6>
                                        <p class="duration">{{certificate.duration}}</p>
                                        <p class="grade">Grade : {{certificate.grade}} out of {{certificate.out_of}}</p>
                                        <p class="uni-con">{{certificate.description}} </p>
                                    </div>

                                </div>
                                <!-- <div class="edu-his mb-25">
                                    <div class="cir-img mb-15">
                                        <img src="assets/img/team/cirti.png" alt="">
                                    </div>
                                    <div class="edu-qua">
                                        <h5 class="uni-name">Bangladesh University of Engineering & Technology</h5>
                                        <h6 class="course-n">Bsc in CSE</h6>
                                        <p class="duration">2018-2022</p>
                                        <p class="grade">Grade : 3.88 out of 4.00</p>
                                        <p class="uni-con">Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. </p>
                                    </div>

                                </div>
                                <div class="edu-his mb-25">
                                    <div class="cir-img mb-15">
                                        <img src="assets/img/team/cirti.png" alt="">
                                    </div>
                                    <div class="edu-qua">
                                        <h5 class="uni-name">Bangladesh University of Engineering & Technology</h5>
                                        <h6 class="course-n">Bsc in CSE</h6>
                                        <p class="duration">2018-2022</p>
                                        <p class="grade">Grade : 3.88 out of 4.00</p>
                                        <p class="uni-con">Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. </p>
                                    </div>

                                </div>
                                <div class="edu-his mb-25">
                                    <div class="cir-img mb-15">
                                        <img src="assets/img/team/cirti.png" alt="">
                                    </div>
                                    <div class="edu-qua">
                                        <h5 class="uni-name">Bangladesh University of Engineering & Technology</h5>
                                        <h6 class="course-n">Bsc in CSE</h6>
                                        <p class="duration">2018-2022</p>
                                        <p class="grade">Grade : 3.88 out of 4.00</p>
                                        <p class="uni-con">Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. </p>
                                    </div>

                                </div> -->
                            </div>


                        </div>

                    </div>
                </div>

            </div>
        </section>
        <!-- about end -->
    </main>
  
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name:"ProfilePage",
  data: () => ({
    baseEnvLocal: "https://timerni.com/"
  }),
  
  async created() {
      this.getProfile();
  },
  computed: {
      ...mapState("homepage",["profile"]),
  }, 
  methods: {
    async getProfile(){
            let id = this.$route.params.id;
            this.$store.dispatch("homepage/getProfile",id).then(() => {
            })
            .catch(() => {});
        }
    },
 };
</script>

<style>
    .page-heading .s-heading {
        color: var(--s-primary-color);
    }
    .s-heading {
        font-weight: 500;
        font-size: 30px!important;
        line-height: 1.5;
        text-align: center;    
        text-transform: uppercase;
    }
        .page-heading {
            padding-top: 35px;
            padding-bottom: 25px;
        }
        .team-page .t-member-profile-img::before {
            content: "";
            position: absolute;
            border-top: 20px solid #312783;
            border-left: 20px solid #312783;
            height: 100%;
            width: 80%;
            left: 0;
        }
        
        .team-page .t-member-profile-img::after {
            content: "";
            position: absolute;
            border-left: 20px solid #E62552;
            border-top: 20px solid #E62552;
            border-bottom: 20px solid #E62552;
            height: 100%;
            width: 85%;
            left: 20px;
            top: 20px;
        }
        
        .team-page .t-member-profile-img img {
            position: relative;
            z-index: 1;
            margin-top: 40px;
            max-width: 100%;
            margin-left: 40px;
        }
        
        .team-page .t-member-des {
            position: relative;
        }
        
        .team-page .t-member-des img {
            width: 90%;
            margin-left: 7%;
        }
        
        .team-page .t-member-des .team-deg {
            position: absolute;
            top: 50%;
            left: 53%;
            width:90%;
            transform: translate(-50%, -50%);
        }
        
        .team-page .t-member-des .team-deg h5 {
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: #FFFFFF;
        }
        
        .team-page .t-member-des .team-deg p {
            font-weight: 400;
            font-size: 13px;
            text-align: center;
            color: #BEC2C8;
        }
        
        .team-page .social-info .f-s-icon>a {
            display: inline-block;
            text-align: center;
            width: 45px;
            height: 45px;
            line-height: 45px;
            border-radius: 50%;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            margin-right: 12px;
        }
        
        .team-page .social-info .f-s-icon>a:nth-last-child(4) {
            background: #2F80EC;
        }
        
        .team-page .social-info .f-s-icon>a:nth-last-child(3) {
            background: #61CFF3;
        }
        
        .team-page .social-info .f-s-icon>a:nth-last-child(2) {
            background: #2D9CDB;
        }
        
        .team-page .social-info .f-s-icon>a:nth-last-child(1) {
            background: #E9455A;
            margin-right: 0px;
        }
        
        .team-page .contct-c {
            font-weight: 400;
            font-size: 14px;
            color: #231F20;
        }
        
        .team-page .t-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            color: #312783;
            margin-bottom: 10px;
        }
        
        .team-page .m-con {
            font-weight: 400;
            font-size: 14px;
            color: #231F20;
            text-align:justify;
        }
        
        .team-page .skills {
            position: relative;
        }
        
        .team-page .skills-bar .bar {
            margin: 15px 0;
        }
        
        .team-page .skills-bar .bar .info span {
            font-size: 1rem;
            font-weight: 500;
        }
        
        .team-page .skills-bar .bar .info {
            margin-bottom: 8px;
            color: #07374a;
            background-color: transparent!important;
        }
        
        .team-page .skills-bar .bar .progress-line {
            position: relative;
            height: 10px;
            width: 100%;
            background: #f0f0f0;
            border-radius: 10px;
            transform: scaleX(0);
            transform-origin: left;
            animation: animate 1s cubic-bezier(1, 0, 0.5, 1) forwards;
        }
        
        .team-page .bar .progress-line span {
            position: absolute;
            background: #25b79f;
            height: 100%;
            border-radius: 10px;
            transform: scaleX(0);
            transform-origin: left;
            text-transfrom: uppercase;
            animation: animate 1s 1s cubic-bezier(1, 0, 0.5, 1) forwards;
        }
        
        @keyframes animate {
            100% {
                transform: scaleX(1);
            }
        }
        
        .team-page .progress-line .html {
            width: 80%;
        }
        
        .team-page .progress-line .css {
            width: 70%;
        }
        
        .team-page .progress-line .bootstrap {
            width: 82%;
        }
        
        .team-page .progress-line .javascript {
            width: 62%;
        }
        
        .team-page .progress-line .c {
            width: 90%;
        }
        
        .team-page .bar .progress-line span::before {
            position: absolute;
            content: "";
            height: 0;
            right: 0;
            top: -14px;
            width: 0;
            border: 7px solid transparent;
            border-bottom-width: 0px;
            border-right-width: 0px;
            border-top-style: #f0f0f0;
            border-top-color: #07374a;
        }
        
        .team-page .bar .progress-line span::after {
            position: absolute;
            right: 0;
            top: -28px;
            color: #f0f0f0;
            font-size: 12px;
            font-weight: 700;
            background: #07374a;
            padding: 1px 8px;
            border-radius: 3px;
        }
        
        .team-page .progress-line .html::after {
            content: "80%";
        }
        
        .team-page .progress-line .css::after {
            content: "70%";
        }
        
        .team-page .progress-line .bootstrap::after {
            content: "82%";
        }
        
        .team-page .progress-line .javascript::after {
            content: "62%";
        }
        
        .team-page .progress-line .c::after {
            content: "90%";
        }
        
        .team-page .education .edu-qua .uni-name {
            font-weight: 500;
            font-size: 14px;
            color: #231F20;
            padding-bottom: 10px;
        }
        
        .team-page .education .edu-qua .duration,
        .team-page .education .edu-qua .uni-con,
        .team-page .education .edu-qua .grade,
        .team-page .education .edu-qua .course-n {
            font-weight: 400;
            font-size: 14px;
            color: #231F20;
            padding-bottom: 3px;
        }
        
        .team-page .education .edu-qua .duration {
            color: #BEC2C8;
        }
        
        .team-page .education .edu-qua .uni-con {
            font-weight: 300;
        }
        
        .team-page .edu-his {
            display: flex;
            gap: 20px;
        }
        
        .team-page .edu-his .edu-img {
            flex-basis: 150px;
        }
        
        .team-page .edu-his .cir-img {
            flex-basis: 250px;
        }
        
        @media (max-width: 767px) {
            .team-page .skills {
                width: 80%;
            }
            .team-page .skills-bar .bar .progress-line {
                width: 100%;
            }
        }
        .mt-25{
            margin-top:25px;
        }
        .mt-20{
            margin-top:20px!important;
        }
    </style>
